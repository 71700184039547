<template>
  <div class="recordIndex-container">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'recordIndex',
  components: {
  }
}

</script>

<style>
</style>
